<template>
  <div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card v-if="tableData.length && header.length">
      <div class="vx-col w-full">
        <div class="vx-row">
          <div class="vx-col sm:w-1/6 w-full">
            <span>{{'ยืนยันการชำระเงิน '+bookLists.length +' รายการ'+'วันที่: '}}</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <datepicker :style="{width: '225px'}" :language="languages[language]" format="dd MMMM yyyy" v-model="date"></datepicker>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-button v-if="org.tax_id !== ''" :style="{width: '225px'}" type="filled" @click="submitPayment()">ยืนยันการชำระเงิน</vs-button>
          </div>
        </div>
      </div>
      <vs-table ref="table" noDataText="ไม่พบข้อมูล" v-model="selected" :data="bookLists"
                class="table-custom mt-5">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>รหัสลูกค้า</vs-th>
          <vs-th>ชื่อสกุล</vs-th>
          <vs-th>รอบบิล</vs-th>
          <vs-th>รหัสอ้างอิง 2</vs-th>
          <vs-th>รวมเงิน</vs-th>
          <vs-th>รวมเงินจาก Excel</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
            <vs-td>
              <p class="product-name font-medium">{{ index + 1 }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.B03 || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.M04 }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.CB04 || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.ref2 || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.sum || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.amount || '-' }}</p>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vx-card>
    <vx-card v-if="tableData.length && header.length">
      <div class="vx-col w-full">
        <div class="vx-row">
          <div class="vx-col w-full">
            <span>{{'รายการที่ไม่สามารถยืนยันการชำระเงินได้: '+purchaseBookLists.length +' รายการ'}}</span>
          </div>
        </div>
      </div>
      <vs-table ref="table" noDataText="ไม่พบข้อมูล" v-model="selected" :data="purchaseBookLists"
                class="table-custom mt-5">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>รหัสลูกค้า</vs-th>
          <vs-th>ชื่อสกุล</vs-th>
          <vs-th>รอบบิล</vs-th>
          <vs-th>รหัสอ้างอิง 2</vs-th>
          <vs-th>รวมเงิน</vs-th>
          <vs-th>รวมเงินจาก Excel</vs-th>
        </template>
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
            <vs-td>
              <p class="product-name font-medium">{{ index + 1 }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.B03 || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.M04 }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.CB04 || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.ref2 || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.sum || '-' }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.amount || '-' }}</p>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
  import ImportExcel from '@/components/excel/ImportExcel.vue'
  import * as lang from "vuejs-datepicker/dist/locale";
  import Datepicker from "vuejs-datepicker";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import {formatNumber2Digit, formatFloat, format2Digit, formatNumber} from "../pdf/assets/utils";
  import moment from "moment";
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
  import moduleConfig from "@/store/config/moduleConfig.js";
  export default {
    components: {
      Datepicker,
      ImportExcel
    },
    data() {
      return {
        date: new Date(),
        language: 'th',
        languages: lang,
        bookLists: [],
        purchaseBookLists: [],
        results: [],
        selected: [],
        tableData: [],
        header: [],
        sheetName: ''
      }
    },
    computed:{
      org() {
        return this.$store.state.config.item;
      },
      billConfigs() {
        let options = [];
        this.$store.state.billConfigs.items.forEach(function (item) {
          options.push({
            'label': item.CB02  + ' (' + item.CB04+ ')',
            'value': item.CB01,
            'data': item
          });
        });
        options.unshift({
          'label': 'ทั้งหมด',
          'value': '',
          'data': ''
        });
        return options;
      },
    },
    methods: {
      submitPayment() {
        this.$vs.dialog({
          type: 'confirm',
          title: `ยืนยันการชำระเงินสำเร็จ`,
          text: 'ยืนยันการชำระเงินสำเร็จใช่หรือไม่',
          accept: this.submitForm
        })
      },
      async submitForm() {
        this.$vs.loading();
        let date = moment(this.date).format('YYYY/MM/DD');
        const user = JSON.parse(sessionStorage.getItem('vuex'));
        const staffName = user.owner.user.user.userFullname;
        const staffId = user.owner.user.user.id;
        if(this.selected.length > 0){
          this.bookLists = this.selected
        }
        for (let i = 0; i < this.bookLists.length; i++) {
          const body = {
            _id: this.bookLists[i]._id,
            staffId: staffId,
            B24: date,
            B09: staffName,
            B10: '1'
          }
          await this.$store.dispatch("bookLists/updateItem", body).then((res) => {
            if (res.status === 200) {
              this.bookLists.splice(i, 1)
            }
          }).catch((error) => {
            this.failDialog(error.toString());
          });
        }
        this.$vs.loading.close();
        this.successDialog()
      },
      failDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'danger',
          title: `ผิดพลาด`,
          text: message,
          acceptText: 'ปิด'
        })
      },
      successDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'success',
          title: `สำเร็จ`,
          text: 'ยืนยันการชำระเงินสำเร็จ',
          acceptText: 'ปิด',
        })
      },
      formatBill(value) {
        let bill = this.$store.state.billConfigs.items.filter((item) => {
          return item.CB01 === value
        })
        if (bill.length === 0){
          return '';
        }else{
          return bill[0].CB04;
        }
      },
      formatDate(text) {
        const date = moment(text).format('DD/MM/YYYY');
        if(date === 'Invalid date') {
          return '-'
        } else {
          const dateSplit = date.split('/');
          const year = parseInt(dateSplit[2]) + 543;
          return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        }
      },
      async loadDataInTable({results, header, meta}) {
        this.header = header
        this.tableData = results
        for (let i = 0; i < this.tableData.length; i++) {
          const data = this.tableData[i]
          try {
            if (data._2.toString().length === 18) {
              let ref2 = data._2
              let amount = data._8
              let memberId = ref2.substring(12, 17)
              let billId = ref2.substring(6, 12)
              let apiBill = billId.replace(' ', '-')
              let rawRef2 = ref2.replace(' ', '')
              this.results.push({B01: apiBill, B03: memberId, rawRef2 , amount})
            }
          }catch (e) {
          // console.log(e)
          }
        }
        for (let i = 0; i < this.results.length; i++) {
          const data = this.results[i]
          await this.$store.dispatch("bookLists/fetchPaymentDataListItems", data).then((res) => {
            let data = res.data[0];
            data.amount = formatNumber(this.results[i].amount)
            data.ref2 = this.results[i].rawRef2
            data.B17 = this.formatDate(data.B17)
            data.B19 = this.formatDate(data.B19)
            data.CB04 = this.formatBill(data.B01)
            const sum = parseFloat(data.BA11) + parseFloat(data.BA12) + parseFloat(data.BA13)
            data.sum = formatNumber(sum)
            if(data.B10 === '0'){
              this.bookLists.push(data)
            }else{
              this.purchaseBookLists.push(data)
            }
          }).catch((error) => {
            return;
          });
        }
      }
    },
    async created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig);
        moduleBillConfig.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule("config", moduleConfig);
        moduleConfig.isRegistered = true;
      }
      await this.$store.dispatch("billConfigs/fetchDataListItems");
      await this.$store.dispatch("config/fetchDataItem");
    }
  }
</script>
